<template>
    <div id="swagger-ui"></div>
  </template>
  
  <script>
  import SwaggerUI from 'swagger-ui'
  import 'swagger-ui/dist/swagger-ui.css'
  import { config } from '../consts/apiUrlConsts'
  
  const openAPIURL = `${config.url.API_URL}/v1/api-docs`;

  export default {
    name: 'ApiTestView',
    mounted() {
      SwaggerUI({
        dom_id: '#swagger-ui',
        url: openAPIURL,  // URL to the OpenAPI JSON
        presets: [
          SwaggerUI.presets.apis,
          SwaggerUI.SwaggerUIStandalonePreset
        ],
        layout: "BaseLayout",
        deepLinking: true,
        defaultModelsExpandDepth: -1,  // 1: display, -1: hide
        docExpansion: "none",
        persistAuthorization : true,
        withCredentials: true,
        filter: true,
      })

    }
  }

  </script>
  
  <style>
  #swagger-ui {
    height: 100vh;
  }
  .base-url, .link {
    display: none !important;
  }
  </style>
  