import axios from 'axios'
import type { AenaFlights, AenaFlightsByAirport } from '@/types/flights/flights.d.ts'
import { GET_FLIGHTS } from '@/consts/flights'
import api from '@/boot/axios'
import authHeader from '../authHeaderService'
import { GET_FLIGHTS_BY_DATE } from '@/consts/flights'

export async function getAirportData(date: string) {
  //const response = await api.get<AenaFlightsByAirport>(GET_FLIGHTS, {
  const response = await api.get<AenaFlightsByAirport>(GET_FLIGHTS_BY_DATE, {
    headers: authHeader(),
    params: {
      date
    }
  })
  return response.data
}
