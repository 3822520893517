import type { Booking } from '@/types/bookings/booking.d.ts'
// import { log } from 'console'

/**
 * converts date in array of numbers to date object
 * @param date
 * @returns
 */
export const toDate = (date: string) => {
  const [day, month, year] = date.split('/')
  return new Date(Number(year), Number(month) - 1, Number(day))
}

/**
 * converts date in array of numbers to string
 * @param date
 * @returns
 */
export const toDateString = (date: number[]) => {
  const [year, month, day] = date

  // Agregar cero delante del mes si es menor que 10
  const formattedMonth = month < 10 ? `0${month}` : month

  // Agregar cero delante del día si es menor que 10
  const formattedDay = day < 10 ? `0${day}` : day

  // Devolver la fecha en el formato "DD-MM-YYYY"
  return `${formattedDay}/${formattedMonth}/${year}`
}

// from HH:mm:ss to HH:mm
export const toSimplifiedTimeString = (time: string) => {
  if (!time) return ''
  return time.split('T')[1].split('.')[0].slice(0, 5)
}
export const toSimplifiedDateString = (date: string) => {
  if (!date) return ''
  return date.split('T')[0]
}
export const toSimplifiedDateTimeString = (date: string) => {
  if (!date) return ''
  return `${toSimplifiedDateString(date)} ${toSimplifiedTimeString(date)}`

}
export const formatTime = (date: Date) => {
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  return `${hours}:${minutes}`

}
export const formatDate = (date: Date) => {
  const year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()

  if (Number(month) < 10) {
    month = `0${month}`
  }
  if (Number(day) < 10) {
    day = `0${day}`
  }
  return `${year}-${month}-${day}`
}
export const formatDateAena = (date: Date) => {
  const year = date.getFullYear().toString()
  let month = (date.getMonth() + 1).toString()
  let day = date.getDate().toString()

  if (Number(month) < 10) {
    month = `0${month}`
  }
  if (Number(day) < 10) {
    day = `0${day}`
  }
  return `${day}/${month}/${year}`
}

export const stringTimetoArray = (time: string) => {
  const [hour, minute] = time.split(':')
  return [Number(hour), Number(minute)]
}
/**
 * converts time in array of numbers to date object
 * @param time

 * @returns
 */
export const toTime = (time: string) => {
  if (time === null || time === undefined) {
    console.log('no time')

    return new Date()
  }
  const [hour, minute] = time.split(':')
  return new Date(0, 0, 0, Number(hour), Number(minute))
}
export const toTimeString = (time: number[]) => {
  const [hour, minute] = time
  const hourStr = hour < 10 ? `0${hour}` : hour
  const minuteStr = minute < 10 ? `0${minute}` : minute
  return `${hourStr}:${minuteStr}`
}

export const formatDateTime = (date: Date) => {
  // Get the components of the date
  const dateParsed = formatDate(date)
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)

  // Construct the date string in the desired format
  return `${dateParsed}T${hours}:${minutes}:${seconds}`
}

/**
 * gets date in format yyyy-mm-dd for the api to work
 *
 * @returns currentDate
 */
export const getCurrentDate = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() + 1 // JavaScript months are 0-11, so we add 1 to get the actual month.
  const day = now.getDate()
  let monthString = month.toString()
  let dayString = day.toString()

  // If month or day are less than 10, prepend them with '0'
  if (month < 10) {
    monthString = '0' + month
  }

  if (day < 10) {
    dayString = '0' + day
  }

  return `${year}-${monthString}-${dayString}`
}
export const slashToHyphen = (date: string) => {
  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}

/**
 * Filter the bookings to get the ones that are in the next and previous 3 hours
 * @param bookings
 * @returns
 */
export const mapBookings = (bookings: Booking[]): Booking[] => {
  return bookings.filter((booking) => {
    const serviceDate = toDate(booking.serviceDate)
    const serviceTime = toTime(booking.serviceTime)
    //Join the date and time
    serviceDate.setHours(
      serviceTime.getHours(),
      serviceTime.getMinutes(),
      serviceTime.getSeconds()
    )

    const now = new Date()
    const threeHoursBefore = new Date(now.getTime() - 3 * 60 * 60 * 1000)
    const threeHoursAfter = new Date(now.getTime() + 3 * 60 * 60 * 1000)
    return (
      booking.vehicleId !== 5000 &&
      booking.serviceAirportType.serviceType === 'ENTRANCE' &&
      // && (booking.referenceCode.toUpperCase().endsWith('-E')
      // || booking.referenceCode.endsWith('/1'))
      serviceDate >= threeHoursBefore &&
      // eslint-disable-next-line no-mixed-operators
      serviceDate <= threeHoursAfter
    )
  })
}
