import type { ToastServiceMethods } from 'primevue/toastservice'

const showSuccess = (
  title: string,
  message: string,
  toast: ToastServiceMethods
) => {
  toast.add({
    severity: 'success',
    summary: title,
    detail: message,
    life: 3000
  })
}
const showError = (error: unknown, toast: ToastServiceMethods) => {
  console.log(error)
  toast.add({
    severity: 'error',
    summary: 'Error',
    detail: getErrorMessage(error),
    life: 3000
  })
}
const showErrorMessage = (error: any, toast: ToastServiceMethods) => {
  let message = ''
  if (error.response && error.response.data.error) {
    message = error.response.data.error.message
  } else {
    message = error as string
  }
  toast.add({
    severity: 'error',
    summary: 'Error',
    detail: message,
    life: 3000
  })
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message
  return String(error)
}

export { showError, getErrorMessage, showSuccess, showErrorMessage }
