<template>
  <Dialog
    :visible="isVisible"
    :modal="true"
    header="Regenerar calendario"
    @update:visible="closeDialog"
  >
    <form @submit.prevent="onSubmit">
      <div class="grid items-center">
        <div class="col-12 md:col-2">Desde</div>
        <div class="col">
          <div class="flex gap-1">
            <div class="flex flex-1">
              <Calendar
                id="calendar-24h"
                v-model="inputs.dateFrom"
                :min-date="minDate"
                hour-format="24"
              />
            </div>
            <div class="flex flex-1">
              <Calendar id="calendar-timeonly" v-model="inputs.dateFrom" time-only />
            </div>
          </div>
          <span v-if="errors.dateFrom" class="text-red text-xs">{{ errors.dateFrom }}</span>
        </div>
      </div>
      <div class="grid mt-1 items-center">
        <div class="col-12 md:col-2">Hasta</div>
        <div class="col">
          <div class="flex gap-1">
            <div class="flex flex-1">
              <Calendar
                id="calendar-24h"
                v-model="inputs.dateTo"
                :min-date="minDate"
                hour-format="24"
              />
            </div>
            <div class="flex flex-1">
              <Calendar
                id="calendar-timeonly"
                v-model="inputs.dateTo"
                :min-date="minDate"
                time-only
              />
            </div>
          </div>
          <span v-if="errors.dateTo" class="text-red text-xs">{{ errors.dateTo }}</span>
        </div>
      </div>
      <div class="grid mt-3 items-center">
        <div class="col-12 md:col-2"></div>
        <div class="col">
          <div class="flex flex-nowrap justify-content-start align-items-center">
            <div class="flex flex-initial ml-2 text-red font-bold">
              Todos los viajes asignados a este conductor serán desasignados. Esta acción no se
              puede revertir.
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-3 items-center">
        <div class="col-12 md:col-2"></div>
        <div class="col">
          <div class="flex flex-nowrap justify-content-start align-items-center">
            <div class="flex flex-initial">
              <Checkbox v-model="inputs.agree" :binary="true" name="agree" input-id="agree" />
            </div>
            <div class="flex flex-initial ml-2 text-red font-bold">
              Marque esta casilla para confirmar lo que está haciendo
            </div>
          </div>
          <span v-if="errors.agree" class="text-red text-xs">{{ errors.agree }}</span>
        </div>
      </div>

      <div class="grid mt-3 items-center">
        <div class="col-12 md:col-2"></div>
        <div class="col">
          <Button
            data-testid="regenerate-button"
            :loading="loading"
            type="submit"
            :label="loading ? 'Regenerando...' : 'Regenerar'"
          />
        </div>
      </div>
    </form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { addDay, dayEnd, dayStart } from '@formkit/tempo'

import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

import type { FormRegenerateCalendarErrors } from '@/types/bookings_manager/persons/Calendar.d.ts'

// const toast = useToast()

const props = defineProps<{
  show: boolean
  loading: boolean
}>()
const errors = ref<FormRegenerateCalendarErrors>({})

const emit = defineEmits([
  'update:show',
  'update:loading',
  'regenerateCalendar'
])

const isVisible = computed({
  get: () => props.show,
  set: (value) => emit('update:show', value)
})

const inputs = ref({
  dateFrom: dayStart(addDay(new Date(), 1)),
  dateTo: dayEnd(addDay(new Date(), 1)),
  agree: false
})

watch(
  () => inputs.value.dateFrom,
  (newValue) => {
    if (newValue) {
      inputs.value.dateTo = dayEnd(inputs.value.dateFrom)
    }
  }
)

const minDate = computed(() => {
  return inputs.value.dateFrom
})

/**
 * Closes the dialog by setting the visibility to false.
 *
 */
const closeDialog = () => {
  isVisible.value = false
}

const validate = () => {
  const errors: Partial<FormRegenerateCalendarErrors> = {}

  if (!inputs.value.dateFrom) {
    errors.dateFrom = 'Este campo es requerido'
  }

  if (!inputs.value.dateTo) {
    errors.dateTo = 'Este campo es requerido'
  }

  if (!inputs.value.agree) {
    errors.agree = 'Este campo es requerido'
  }

  return errors
}
/**
 * Submit the form data after validation and update the availability for the driver.
 *
 * @return {boolean} Returns false if there are input errors, otherwise returns nothing.
 */
const onSubmit = async () => {
  const inputErrors = validate()
  if (Object.keys(inputErrors).length > 0) {
    errors.value = inputErrors
    return false
  }
  emit('regenerateCalendar', inputs.value.dateFrom, inputs.value.dateTo)

  closeDialog()
  // Reset the form
  inputs.value = {
    dateFrom: dayStart(addDay(new Date(), 1)),
    dateTo: dayEnd(addDay(new Date(), 1)),
    agree: false
  }
  errors.value = {}
}
</script>
