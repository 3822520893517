<script setup lang="ts">
import { onMounted } from 'vue'
import { useFlightStore } from '@/stores/flights/flightStore'

const flightStore = useFlightStore()

onMounted(() => {

  flightStore.startFetchingData()
})
</script>

<template>
  <RouterView />
</template>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #d0eff91a;
}

#app {
  background-color: #f8f8f8;
  height: 100%;
}
</style>
