<template>
  <div>
    <span v-if="event.event.extendedProps && event.event.extendedProps.isEditable" class="ml-2">
      <i class="fa-regular fa-pen-to-square"></i>
    </span>

    <span v-if="event.event.extendedProps && event.event.extendedProps.serviceType" class="text-red-700 ml-2">
      <b>{{ event.event.extendedProps.serviceType }}</b>
    </span>

    <span v-if="event.event.extendedProps && event.event.extendedProps.origin" class="ml-2">
      <b>{{ event.event.extendedProps.origin }}</b>
    </span>

    <span class="ml-2">
      {{ event.timeText }}
    </span>

    <span v-if="event.event.extendedProps && event.event.extendedProps.destination" class="ml-2">
      <i class="fa-solid fa-arrow-right"></i>
    </span>

    <span v-if="event.event.extendedProps && event.event.extendedProps.destination" class="ml-2">
      <b> {{ event.event.extendedProps.destination }}</b>
    </span>

    <span v-if="event.event.extendedProps && event.event.extendedProps.passengers" class="ml-4">
      <i class="fa-solid fa-user"></i>
      {{ event.event.extendedProps.passengers }}
    </span>

    <span v-if="event.event.extendedProps && event.event.extendedProps.flightCode" class="ml-4">
      <i class="fa-solid fa-plane"></i>
      <span class="ml-2">{{ event.event.extendedProps.flightCode }}</span>
      <span class="ml-2"> {{ event.event.extendedProps.flightTime }}</span>
    </span>

    <span class="ml-2">
        <b> {{ event.event.title }}</b>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { EventInput } from '@fullcalendar/core'

const props = defineProps<{
  event: EventInput
}>()

const event = computed(() => props.event)
</script>
