import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSharedDateStore = defineStore('sharedDate', () => {
  const date = ref<Date>(new Date(Date.now() + 24 * 60 * 60 * 1000)) // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

  return {
    date
  }
})
